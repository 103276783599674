<template>
  <base-section
    id="values"
    class="grey lighten-4 pt-1"
  >
    <base-section-heading
      title="Comparação dos valores"
      class="mt-16"
    />
    <v-container>
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          class="pt-0"
          cols="12"
        >
          <v-simple-table
            class="mt-0"
            dense
          >
            <tbody class="body-1">
              <tr
                v-for="(plan, i) in prices"
                :key="i"
              >
                <td v-html="`<h2>${plan.local}</h2>`" />
                <td class="pa-4">
                  <v-row>
                    <v-col
                      cols="12"
                      md="4"
                      sm="12"
                    >
                      <v-chip
                        class="ma-2"
                        color="blue lighten-5"
                      >
                        Mensal
                      </v-chip>
                      <base-plan-value
                        :info="`${plan.local}`"
                        :value="plan.special ? plan.month[2] : plan.month[1]"
                        :full-value="plan.month[0]"
                        :special="plan.special"
                        frequency="mês"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                      sm="12"
                    >
                      <v-chip
                        class="ma-2"
                        color="blue lighten-3"
                      >
                        Trimestral
                      </v-chip>
                      <base-plan-value
                        :info="`${plan.local}`"
                        :value="plan.special ? plan.trimester[2] : plan.trimester[1]"
                        :full-value="plan.trimester[0]"
                        :special="plan.special"
                        frequency="trimestre"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                      sm="12"
                    >
                      <v-chip
                        class="ma-2"
                        color="blue lighten-1"
                      >
                        Casal*
                      </v-chip>
                      <base-plan-value
                        :info="`${plan.local}`"
                        :value="plan.special ? plan.couple[2] : plan.couple[1]"
                        :full-value="plan.couple[0]"
                        :special="plan.special"
                        frequency="cada um"
                      />
                    </v-col>
                  </v-row>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td
                  colspan="2"
                  class="grey--text grey lighten-3"
                >
                  * Valor válido apenas para consulta conjunta do casal
                </td>
              </tr>
            </tfoot>
          </v-simple-table>
          <v-alert
            v-if="!offer"
            color="red lighten-1"
            dark
          >
            <v-icon>
              mdi-alert-decagram
            </v-icon>
            OFERTA ESPECIAL PARA PAGAMENTO ADIANTADO NOS PLANOS PRESENCIAIS
            <v-btn
              color="amber"
              class="float-right"
              light
              @click="offer = !offer"
            >
              <v-icon left>
                mdi-eye
              </v-icon>
              MOSTRAR AGORA!
            </v-btn>
          </v-alert>
          <v-alert
            v-else
            color="grey lighten-2"
          >
            Visualizar preços normais
            <v-btn
              color="grey darken-1"
              class="float-right"
              dark
              @click="offer = !offer"
            >
              <v-icon left>
                mdi-eye-off
              </v-icon>
              Retornar
            </v-btn>
          </v-alert>
        </v-col>
      </v-row>
      <v-row justify="space-around">
        <v-col
          cols="10"
          class="pa-0"
        >
          <v-row
            align="center"
            justify="center"
          >
            <v-img
              max-width="314"
              max-height="80"
              src="@/assets/garantia.png"
            />
          </v-row>
          <base-info-card title="Condições especiais">
            <p>Nos planos trimestrais o pagamento pode ser feito em 2x no primeiro e segundo mês, respectivamente.</p>
            <p>Desconto especial para qualquer plano presencial pago até 1 dia antes da consulta.</p>
          </base-info-card>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>
<script>
  export default {
    name: 'SectionValues',

    data: () => ({
      offer: false,
    }),

    computed: {
      prices: function () {
        return [
          {
            local: 'Online',
            icon: 'mdi-wifi',
            month: [350, 350],
            trimester: [950, 950],
            couple: [262.5, 262.5],
            special: false,
          },
          {
            local: 'Chapecó/SC',
            month: [450, 450, 400],
            trimester: [1350, 1250, 1100],
            couple: [337.50, 337.5, 300],
            special: this.offer,
          },
          {
            local: 'São Carlos/SC',
            month: [400, 400, 350],
            trimester: [1200, 1100, 950],
            couple: [300, 300, 262.5],
            special: this.offer,
          },
          {
            local: 'Palmitos/SC',
            month: [400, 400, 350],
            trimester: [1200, 1100, 950],
            couple: [300, 300, 262.5],
            special: this.offer,
          },
        ]
      },
    },
  }
</script>
